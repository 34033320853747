@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: Montserrat, sans-serif;
}

body {
    background-color: #ffffff;
    font: 400 14px Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea {
    font: 400 14px Montserrat, sans-serif;
}

button {
    cursor: pointer;
}

form {
    display: block;
}

form input {
    height: 45px;
    color: #333;
    font-size: 18px;
    border: solid 1px #666666;
    background-color: #ffffff;
    padding: 0 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.label-content {
    display: flex;
    margin: auto
}

.input-label {
    cursor: text;
    color: #666666;
    font-size: 1.3em;
    margin-top: 1.7em;
    margin-left: 0.7em;
    position: absolute;
    transition: font-size 0.4s, margin-top 0.4s, margin-left 0.4s;
}

input:focus + .input-label {
    font-size: 1em;
    margin-left: 0.4em;
    margin-top: -0.3em;
}

input:valid + .input-label {
    font-size: 1em;
    margin-left: 0.4em;
    margin-top: -0.3em;
}

input:invalid + .input-label {
    font-size: 1.3em;
    margin-top: 1.7em;
    margin-left: 0.7em;
}

input[type='checkbox'] {
    width: 20px;
    color: #333;
    height: 20px;
    margin-right: 12px;
    border: solid 1px #666666;
    background-color: #ffffff;
}

.back-text {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.19;
    text-align: left;
    color: #3b69ff;
}

.back-text:hover {
    cursor: pointer;
}

.back-icon-title {
    width: 40px;
    height: 23px;
    padding-top: 4px;
}

.back-icon-title:hover {
    cursor: pointer;
}

.back-icon {
    height: 18px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 1.19;
    text-align: left;
    color: #3b69ff;
}

.back-icon:hover {
    cursor: pointer;
}

.footer-buttons {
    display: flex;
    margin-top: 46px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer-buttons .pb-container {
    margin-left: 10px;
}

.content-container {
    margin: 5em auto;
}

.content-container section {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.content-container section h3 {
    height: 40px;
    margin: auto;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 0.91;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
}

.content-container section p {
    padding: 2px;
    height: 66px;
    object-fit: contain;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
    color: #000000;
    margin-bottom: 48px;
}

.content-container section form {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.content-container section form .checkbox-label {
    width: 320px;
    height: 22px;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    line-height: 1.22;
    text-align: left;
    color: #666666;
}

.content-container section form button {
    margin: auto;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    text-align: center;
    color: #ffffff;
    width: 212px;
    height: 48px;
    border-radius: 50px;
    border: none;
    background-color: #e1173f;
}

.content-container section form button:hover {
    background-color: #ca1a3b;
}

.content-container section form .checkbox-label span {
    cursor: pointer;
    text-decoration: underline;
}

.customLink {
    width: 306px;
    height: 19px;
    object-fit: contain;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 1.19;
    text-align: left;
    color: #3b69ff;
    margin-top: 63px;
    text-decoration: underline;
    cursor: pointer;
}

/* PAGE LOADING*/
.loading-data {
    margin: auto;
    text-align: center;
}

.loader-text {
    margin-top: 50px
}

/*BUTTON STYLE*/
.pb-container {
    text-align: center;
    display: inline-block;
}
.pb-container .pb-button {
    text-align: center;
    color: #ffffff;
    width: 212px;
    height: 48px;
    border-radius: 50px;
    border: none;
    background-color: #e1173f;
    cursor: pointer;
    padding: 0.7em 1em;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
}
.pb-container .pb-button span {
    display: inherit;
    transition: opacity 0.3s 0.1s;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    text-align: center;
}
.pb-container .pb-button svg {
    height: 54px;
    width: 54px;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
}
.pb-container .pb-button svg path {
    opacity: 0;
    fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
    animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
}
.pb-container .pb-button svg.pb-progress-circle path {
    stroke: #e1173f;
    stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
    stroke: #fff;
    stroke-linecap: round;
    stroke-width: 4;
}
.pb-container.disabled .pb-button {
    cursor: not-allowed;
}
.pb-container.loading .pb-button {
    width: 54px;
    border-width: 6.5px;
    border-color: #ddd;
    cursor: wait;
    background-color: transparent;
    padding: 0;
}
.pb-container.loading .pb-button span {
    transition: all 0.15s;
    opacity: 0;
    display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
    transition: opacity 0.15s 0.3s;
    opacity: 1;
}
.pb-container.success .pb-button {
    border-color: #A0D468;
    background-color: #A0D468;
}
.pb-container.success .pb-button span {
    transition: all 0.15s;
    opacity: 0;
    display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
    opacity: 1;
}
.pb-container.error .pb-button {
    border-color: #ED5565;
    background-color: #ED5565;
}
.pb-container.error .pb-button span {
    transition: all 0.15s;
    opacity: 0;
    display: none;
}
.pb-container.error .pb-button .pb-cross > path {
    opacity: 1;
}
@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
        transform-origin: center center;
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
        transform-origin: center center;
    }
}

.react-confirm-alert-body h1 {
    color: #e1173f;
}

@media only screen and (max-width: 980px) {
    .footer-buttons {
        padding: 8px;
        display: flex;
        margin-top: 46px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
