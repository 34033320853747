.content {
    display: flex;
    flex-direction: column;
}

.page-title {
    width: 430px;
    height: 27px;
    color: #000000;
    font-size: 22px;
    margin-top: 2em;
    font-weight: bold;
    line-height: 0.91;
    text-align: center;
    align-self: center;
    font-family: Montserrat, sans-serif;
}

.page-description {
    padding: 2px;
    height: 44px;
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    line-height: 1.22;
    margin-bottom: 2em;
    align-self: center;
    text-align: center;
    font-family: Montserrat, sans-serif;
}

.menu {
    display: flex;
    flex-direction: column;
}

.menu-title {
    /* Font Style */
    font-size: 20px;
    font-family: 'Baloo Bhai', cursive;
    color: #00c200;
    /* Alignment */
    margin: 40px auto 0;
}

.menu-cards {
    display: flex;
    margin: 2em auto;
    flex-direction: row;
}

.card {
    border: 0.2px solid #c8c8c8;
    margin: 0 12px 60px;
    display: flex;
    flex-direction: column;

    font-family: 'Roboto', cursive;
    font-size: 14px;

    flex-flow: column;
    width: 280px;
    height: 370px;
}

.card:hover {
    cursor: pointer;
    /*border: 0.2px solid #00c200;*/
}

.card-title {
    margin-top: 10px;
    margin-bottom: 0 !important;
    width: 270px;
    height: 22px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.card-description {
    display: flex;
    flex-direction: column;;
}

.card-sub {
    margin-top: 10px;
    margin-left: 12px;
    margin-bottom: 30px;
    overflow: hidden;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
}

.card-button {
    align-self: center;
    width: 212.3px;
    height: 48px;
    border-radius: 50px;
    border: none;
    background-color: #e1173f;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.menu-image {
    max-width: 300px;
}

@media only screen
and (min-device-width: 375px)
and (max-device-width: 960px) {
    .menu-cards {
        display: flex;
        margin: 2em auto;
        flex-direction: column;
    }

}
